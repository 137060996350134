.contact_banner{
    background: url('../images/Contact_Banner.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 50vh;
    position: relative;
} 
.banner_info{
    padding-top: 15vh;
}
.banner_info > ul{
    background-color: gray;
    max-width: 175px;
    padding: 5px;
    border-radius: 999px;
}
.banner_info > ul > li{
    display: inline-block;
}
.banner_info > ul > li > a{
    color: white;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
}
.banner_info .banner_infoIcon {
    color: #ffcc29;
    font-size: 24px !important;
}
.banner_info > h1{
    color: white;
    margin-top: 10px;
    font-size: 50px;
    font-weight: 700;
    text-transform: capitalize;
}

/* Responsive Media Queries */

@media all and (max-width: 991px){
    .banner_info > ul{
        max-width: 170px;
    }
    .banner_info > h1{
        font-size: 48px;
    }
}

@media all and (max-width: 768px){
    .banner_info > h1{
        font-size: 46px;
    }
}


@media all and (max-width: 425px){
    .banner_info > ul > li > a{
        font-size: 14px;
    }
    .banner_info .banner_infoIcon{
        font-size: 20px !important;
    }
    .banner_info > ul{
        max-width: 150px;
    }
    .banner_info > h1{
        font-size: 40px;
    }
}

@media all and (max-width: 375px){
    .banner_info > ul > li > a{
        font-size: 14px;
    }
    .banner_info .banner_infoIcon{
        font-size: 20px !important;
    }
    .banner_info > ul{
        max-width: 150px;
    }
    .banner_info > h1{
        font-size: 40px;
    }
}

@media all and (max-width: 320px){
    .banner_info > ul > li > a{
        font-size: 14px;
    }
    .banner_info .banner_infoIcon{
        font-size: 20px !important;
    }
    .banner_info > ul{
        max-width: 150px;
    }
    .banner_info > h1{
        font-size: 40px;
    }
}