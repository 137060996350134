.footer{
    position: relative;
    background-color: #011627;
}
.footer_top{
    position: relative;
    padding: 50px 0;
}
.footer_topSingle > h2{
    color: white;
    font-size: 30px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 20px;
}
.footer_topSingle > p{
    color: #eeeeee;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 20px;
}
.footer_topSingle > ul > li{
    display: inline-block;
    background-color: white;
    margin-right: 5px;
    border-radius: 999px;
    width: 40px;
    height: 40px;
    line-height: 35px;
    text-align: center;
}
.footer_topSingle .fb_icon{
    color: #4267B2 !important;
}
.footer_topSingle .insta_icon{
    color: #C13584 !important;
}
.footer_topSingle .twitter_icon{
    color:#1DA1F2 !important;
}
.footer_topSingle .yt_icon{
    color: #FF0000 !important;
}
.footer_topSingleAddr{
    color: #eeeeee;
    display: flex;
    margin-bottom: 20px;
}
.footer_topSingleAddr p{
    color: #eeeeee;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
}
.footer_topSingleIcon{
    margin-right: 10px;
    color: #ffcc29;
    font-size: 24px !important;
}
.footer_bottom{
    position: relative;
}
.footer_bottomCopyright{
    padding: 20px 0;
    text-align: center;
    border-top: 1px solid lightgray;
}
.footer_bottomCopyright > p{
    color: white;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
}
.footer_bottomCopyright > p > a{
    color: #ffcc29;
    font-weight: 500;
}

/* Responsive Media Queries */
@media all and (max-width: 991px){
    .footer_topSingle{
        margin-bottom: 30px;
    }
    .footer_top{
        padding: 40px 0;
    }
}

@media all and (max-width: 768px){
    .footer_topSingle > h2{
        font-size: 28px;
    }
    .footer_top{
        padding: 30px 0;
    }
    .footer_topSingle > ul > li{
        width: 35px;
        height: 35px;
        line-height: 30px;
    }
}


@media all and (max-width: 425px){
    .footer_topSingle > h2{
        font-size: 24px;
    }
    .footer_topSingle > p{
        font-size: 14px;
    }
    .footer_topSingle > ul > li{
        width: 32px;
        height: 32px;
        line-height: 27px;
    }
    .footer_topSingleAddr p{
        font-size: 14px;
    }
}


@media all and (max-width: 375px){
    .footer_topSingle > h2{
        font-size: 24px;
    }
    .footer_topSingle > p{
        font-size: 14px;
    }
    .footer_topSingle > ul > li{
        width: 32px;
        height: 32px;
        line-height: 27px;
    }
    .footer_topSingleAddr p{
        font-size: 14px;
    }
}

@media all and (max-width: 320px){
    .footer_topSingle > h2{
        font-size: 24px;
    }
    .footer_topSingle > p{
        font-size: 14px;
    }
    .footer_topSingle > ul > li{
        width: 32px;
        height: 32px;
        line-height: 27px;
    }
    .footer_topSingleAddr p{
        font-size: 14px;
    }
}