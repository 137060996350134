.whowe_are{
    position: relative;
    background-color: white;
    padding: 80px 0;
}
.whowe_areText span{
    color: #ffcc29;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
}
.whowe_areText h2{
    color: #373435;
    font-size: 30px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 20px;
}
.whowe_areText p {
    color: #767273;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    line-height: 30px;
    margin-bottom: 10px;
}
.whowe_areImage > img{
    object-fit: contain;
}

/* Responsive Media Queries */
@media all and (max-width: 991px){
    .whowe_areText{
        margin-bottom: 30px;
    }
}

@media all and (max-width: 768px){
    .whowe_areText h2{
        font-size: 24px;
    }
}


@media all and (max-width: 425px){
    .whowe_are{
        padding: 50px 0;
    }
    .whowe_areText span{
        font-size: 14px;
    }
    .whowe_areText h2{
        font-size: 20px;
    }
    .whowe_areText p{
        font-size: 14px;
        line-height: 28px;
    }
}

@media all and (max-width: 375px){
    .whowe_are{
        padding: 50px 0;
    }
    .whowe_areText span{
        font-size: 14px;
    }
    .whowe_areText h2{
        font-size: 20px;
    }
    .whowe_areText p{
        font-size: 14px;
        line-height: 28px;
    }
}

@media all and (max-width: 320px){
    .whowe_are{
        padding: 50px 0;
    }
    .whowe_areText span{
        font-size: 14px;
    }
    .whowe_areText h2{
        font-size: 20px;
    }
    .whowe_areText p{
        font-size: 14px;
        line-height: 28px;
    }
}