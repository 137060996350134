.contact_info{
    position: relative;
    background-color: white;
    padding: 80px 0;
}
.contact_infoTitle{
    position: relative;
    margin-bottom: 40px;
}
.contact_infoTitle > h2{
    color: #373435;
    font-size: 30px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 10px;
}
.contact_infoTitle > p{
    color: #727376;
    font-size: 16px;
    font-weight: 400;
}
.single_address{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}
.single_addressIcon{
    color: #ffcc29;
    font-size: 50px !important; 
    background-color: gray;
    padding: 10px;
    border-radius: 999px;
}
.single_addressInfo{
    padding-left: 25px;
}
.single_addressInfo > h4{
    color: #373435;
    font-size: 24px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 7px; 
}
.single_addressInfo > p{
    color: #727376;
    font-weight: 500;
    font-size: 16px;
}
.form_wrapper{
    position: relative;
}
.form_wrapper label {
    display: block;
    margin-bottom: 0;
}
.form_wrapper input, textarea {
    display: block;
    width: 100%;
    height: 50px;
    line-height: 45px;
    padding: 0 20px;
    margin-bottom: 20px;
    outline-width: 0;
    border: 2px solid rgba(0,0,0,0.1);
    border-radius: 10px;
    color: #373435;
    font-size: 15px;
    transition: all 0.3s ease;
}
.form_wrapper textarea{
    height: 120px;
}
.form_wrapper button {
    background-color: #ffcc29 !important;
    color: #373435;
    font-weight: 600;
    text-transform: inherit;
    border: 1px solid #727376;
    border-radius: 99px;
    outline: none;
}
.contact_info .Row_two {
    padding-bottom: 40px;
}
.form_wrapperMsg{
    margin-bottom: 20px;
    background-color: #ffcc29;
    color: #373435;
    text-align: center;
    margin: 0 auto;
    width: 350px;
    padding: 3px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    display: none;
}
.form_wrapperMsgAppear{
    display: block;
}

/* Responsive Media Queries */

@media all and (max-width: 768px){
    .contact_infoTitle > h2{
        font-size: 28px;
    }
    .single_addressIcon{
        font-size: 45px !important;
    }
    .single_addressInfo > h4{
        font-size: 22px;
    }
    .form_wrapper{
        margin: 10px 0;
    }
}


@media all and (max-width: 425px){
    .contact_info{
        padding: 60px 0;
    }
    .contact_infoTitle > h2{
        font-size: 22px;
    }
    .contact_infoTitle > p{
        font-size: 14px;
    }
    .single_addressIcon{
        font-size: 40px !important;
    }
    .single_addressInfo > h4{
        font-size: 20px;
    }
    .single_addressInfo > p{
        font-size: 14px;
    }
}


@media all and (max-width: 375px){
    .contact_info{
        padding: 60px 0;
    }
    .contact_infoTitle > h2{
        font-size: 22px;
    }
    .contact_infoTitle > p{
        font-size: 14px;
    }
    .single_addressIcon{
        font-size: 40px !important;
    }
    .single_addressInfo > h4{
        font-size: 20px;
    }
    .single_addressInfo > p{
        font-size: 14px;
    }
}

@media all and (max-width: 320px){
    .contact_info{
        padding: 60px 0;
    }
    .contact_infoTitle > h2{
        font-size: 22px;
    }
    .contact_infoTitle > p{
        font-size: 14px;
    }
    .single_addressIcon{
        font-size: 40px !important;
    }
    .single_addressInfo > h4{
        font-size: 20px;
    }
    .single_addressInfo > p{
        font-size: 14px;
    }
}