
/* Home Slider Css */

.d-table{
    width: 100%;
    height: 100%;
}
.d-table-cell{
    vertical-align: middle;
}
img{
    max-width: 100%;
}
.main-banner{
    position: relative;
    height: 90vh;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.main-banner::before{
    position: absolute;
    content: "";
    background-color: #373435;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0.40;
}
.item-bg-one{
    background-image: url('../images/Home_One.jpg');
}
.item-bg-two{
    background-image: url('../images/Home_Two.jpg');
}
.item-bg-three{
    background-image: url('../images/Home_Three.jpg');
}
.item-bg-four{
    background-image: url('../images/Home_Four.jpg');
}
.main-banner-text{
    text-align: center;
    padding: 0px 0 0;
}
.main-banner-text h1{
    color:white;
    font-size: 60px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 10px;
}
.main-banner h1 span{
    color: #ff2c57;
    font-weight: 600;
}
.main-banner-text p{
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 16px;
    max-width: 700px;
    margin: auto;
    margin-bottom: 20px;
}
.main-banner-text button{
    background-color: #ffcc29;
    color: white;
    font-size: 16px;
    font-weight: 600;
    text-transform: inherit;
    outline-width: 0;
    text-decoration: none !important;
}
.main-banner-text button:hover{
    background-color: #ffcc29;
    color: white;
}
.owl-theme .owl-nav{
    margin-top: 0;
}
.owl-theme .owl-nav [class*="owl-"] {
    position: absolute;
    top: 50%;
    left: 20px;
    background-color: #ff2c57 !important;
    width: 60px;
    height: 60px;
    color: #fff;
    line-height: 55px;
    text-align: center;
    font-size: 30px !important;
    border-radius: 30px !important;
    transition: all 0.3s ease;
}
.owl-theme .owl-nav [class*="owl-"]:hover{
    background-color: #C73336;
    color: #fff;
}
.owl-theme .owl-nav .owl-next{
    left: auto;
    right: 20px;
    cursor: pointer;
}
.owl-theme .owl-nav .owl-prev{
    transform: rotate(180deg);
    cursor: pointer;
}

.owl-theme .owl-dots {
    line-height: .1;
    position: absolute;
    left: 46%;
    bottom: 100px;
    transition: all 0.3s ease;
  }
  
  .owl-theme .owl-dots .owl-dot span {
    width: 12px !important;
    height: 12px !important;
    margin: 0 5px;
    background: #373435 !important;
    border-radius: 50%;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .owl-theme .owl-dots .owl-dot:hover span, .owl-theme .owl-dots .owl-dot.active span {
    background: #ffcc29 !important;
  }

  /* Responsive Media Queries */
  @media all and (max-width: 991px){
    .main-banner-text h1{
        font-size: 55px;
    }
  }

  @media all and (max-width: 768px){
    .main-banner-text h1{
        font-size: 50px;
    }
  }

  
  @media all and (max-width: 425px){
    .main-banner{
        height: 70vh;
    }
    .main-banner-text h1{
        font-size: 30px;
    }
    .main-banner-text p{
        font-size: 14px;
    }
    .main-banner-text button{
        font-size: 14px;
    }
    .owl-theme .owl-dots{
        left: 35%;
        bottom: 35px;
    }
    .owl-theme .owl-dots .owl-dot span{
        width: 10px !important;
        height: 10px !important;
    }
  }

  
  @media all and (max-width: 375px){
    .main-banner{
        height: 70vh;
    }
    .main-banner-text h1{
        font-size: 30px;
    }
    .main-banner-text p{
        font-size: 14px;
    }
    .main-banner-text button{
        font-size: 14px;
    }
    .owl-theme .owl-dots{
        left: 35%;
        bottom: 35px;
    }
    .owl-theme .owl-dots .owl-dot span{
        width: 10px !important;
        height: 10px !important;
    }
  }

  @media all and (max-width: 320px){
    .main-banner{
        height: 70vh;
    }
    .main-banner-text h1{
        font-size: 30px;
    }
    .main-banner-text p{
        font-size: 14px;
    }
    .main-banner-text button{
        font-size: 14px;
    }
    .owl-theme .owl-dots{
        left: 35%;
        bottom: 35px;
    }
    .owl-theme .owl-dots .owl-dot span{
        width: 10px !important;
        height: 10px !important;
    }
  }