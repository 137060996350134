.vimission{
    position: relative;
    overflow: hidden;
    padding: 40px 0;
    background: rgba(0,0,0,0.99);
}
.vimission_info{
    height: 100%;
}
.vimission_info h4{
    color: #ffcc29;
    font-size: 30px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 20px;
}
.vimission_info p{
    color: white;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}
.vimission_info > ul{
    color: white;
    margin-bottom: 0;
}
.vimission_info > ul > li{
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 7px;
    font-family: 'Roboto', sans-serif;
}

/* Responsive Media Queries */
@media all and (max-width: 991px){
    .vimission_info{
        margin-bottom: 30px;
    }
    .vimission_info h4{
        margin-bottom: 10px;
    }
}

@media all and (max-width: 768px){
    .vimission_info h4{
        font-size: 24px;
    }
}

@media all and (max-width: 425px){
    .vimission_info h4{
        font-size: 20px;
    }
    .vimission_info p{
        font-size: 14px;
        line-height: 28px;
    }
    .vimission_info > ul > li{
        font-size: 14px;
        line-height: 28px;
    }
}

@media all and (max-width: 375px){
    .vimission_info h4{
        font-size: 20px;
    }
    .vimission_info p{
        font-size: 14px;
        line-height: 28px;
    }
    .vimission_info > ul > li{
        font-size: 14px;
        line-height: 28px;
    }
}

@media all and (max-width: 320px){
    .vimission_info h4{
        font-size: 20px;
    }
    .vimission_info p{
        font-size: 14px;
        line-height: 28px;
    }
    .vimission_info > ul > li{
        font-size: 14px;
        line-height: 28px;
    }
}