.pb-80{
    padding-bottom: 80px;
}
.mb-30{
    margin-bottom: 30px;
}
.process{
    background-color: #f1f1f1;
    padding: 80px 0;
}
.processText{
    margin-bottom: 30px;
}
.processText > h2{
    color: #373435;
    font-size: 28px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 10px;
}
.processText > p{
    color: #767273;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.8;
    margin-bottom: 10px;
}
.processText > ul{
    list-style: none;
}
.processText > ul > li{
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: #767273;
    margin-bottom: 5px;
    font-weight: 500;
}
.processTextIcon{
    color: #767273;
    font-size: 24px !important;
}


/* Responsive Media Queries */
@media all and (max-width: 991px){
    .processImage{
        margin-top: 30px;
    }
}

@media all and (max-width: 768px){
    .processText > h2{
        font-size: 24px;
    }
}


@media all and (max-width: 425px){
    .process{
        padding: 50px 0;
    }
    .processText > h2{
        font-size: 20px;
    }
    .processText > p{
        font-size: 14px;
    }
    .processText > ul > li{
        font-size: 14px;
    }
    .processTextIcon{
        font-size: 20px !important;
    }
    .processImage{
        margin-top: 10px;
    }
}


@media all and (max-width: 375px){
    .process{
        padding: 50px 0;
    }
    .processText > h2{
        font-size: 20px;
    }
    .processText > p{
        font-size: 14px;
    }
    .processText > ul > li{
        font-size: 14px;
    }
    .processTextIcon{
        font-size: 20px !important;
    }
    .processImage{
        margin-top: 10px;
    }
}

@media all and (max-width: 320px){
    .process{
        padding: 50px 0;
    }
    .processText > h2{
        font-size: 20px;
    }
    .processText > p{
        font-size: 14px;
    }
    .processText > ul > li{
        font-size: 14px;
    }
    .processTextIcon{
        font-size: 20px !important;
    }
    .processImage{
        margin-top: 10px;
    }
}
