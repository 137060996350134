.productRow{
    padding-top: 80px;
}
.products{
    position: relative;
    margin-bottom: 30px;
}
.products h2{
    color: #373435;
    font-size: 24px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 10px;
}
.products ul li{
    color: #767273;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    text-transform: capitalize;
    line-height: 2.2;
}
.special_process{
    position: relative;
    margin-bottom: 30px;
}
.special_process h2{
    color: #373435;
    font-size: 24px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 10px;
}
.special_process ul li{
    color: #767273;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    text-transform: capitalize;
    line-height: 2.2;
}
.capacity{
    position: relative;
    margin-bottom: 30px;
}
.single_capacity{
    position: relative;
    margin-bottom: 15px;
}
.capacity h2{
    color: #373435;
    font-size: 24px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 10px;
}
.capacity ul li{
    color: #767273;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    text-transform: capitalize;
    line-height: 2.2;
}
.productsImage{
    margin-bottom: 30px;
}
.productsImage > img{
    object-fit: contain;
}
.capicityIcon{
    color: #767273;
}
.pt-80{
    padding-top: 80px;
}

/* Responsive Media Queries */

@media all and (max-width: 425px){
    .productRow{
        padding-top: 50px;
    }
    .products h2{
        font-size: 20px;
    }
    .products ul li{
        font-size: 14px;
    }
    .special_process h2{
        font-size: 20px;
    }
    .special_process ul li{
        font-size: 14px;
    }
    .capacity h2{
        font-size: 20px;
    }
    .capacity ul li{
        font-size: 14px;
    }
}

@media all and (max-width: 375px){
    .productRow{
        padding-top: 50px;
    }
    .products h2{
        font-size: 20px;
    }
    .products ul li{
        font-size: 14px;
    }
    .special_process h2{
        font-size: 20px;
    }
    .special_process ul li{
        font-size: 14px;
    }
    .capacity h2{
        font-size: 20px;
    }
    .capacity ul li{
        font-size: 14px;
    }
}

@media all and (max-width: 320px){
    .productRow{
        padding-top: 50px;
    }
    .products h2{
        font-size: 20px;
    }
    .products ul li{
        font-size: 14px;
    }
    .special_process h2{
        font-size: 20px;
    }
    .special_process ul li{
        font-size: 14px;
    }
    .capacity h2{
        font-size: 20px;
    }
    .capacity ul li{
        font-size: 14px;
    }
}