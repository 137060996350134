*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Quicksand', sans-serif;
}
ul{
  margin-bottom: 0;
  list-style: none;
}
h1,h2,h3,h4,h5,h6{
  margin-bottom: 0;
}
p{
  margin: 0;
  font-family: 'Roboto', sans-serif;
}
.ptb-80{
  padding: 80px 0;
}
.ptb-100{
  padding: 100px 0; 
}
img{
  max-width: 100%;
}
