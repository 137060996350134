.topbar{
    position: relative;
    background-color: black;
    padding: 10px;
}
.topbar_left p{
    color: white;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
}
.topbar_right{
    padding-right: 20px;
}
.topbar_right p{
    color: white;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    text-align: end;
}
.topbar_leftIcon{
    color: #ffcc29;
    margin-right: 5px;
    font-size: 24px !important;
}
.topbar_rightIcon{
    color: #ffcc29;
    margin-right: 5px;
    font-size: 24px !important;
}

/* Responsive Media Queries */
@media all and (max-width: 991px){
    .topbar_right{
        padding-right: 0px;
    }
}

@media all and (max-width: 768px){
    .topbar{
        padding: 12px;
    }
}


@media all and (max-width: 425px){
    .topbar_left p{
        font-size: 14px;
        text-align: center;
    }
    .topbar_right p{
        font-size: 14px;
        text-align: center;
    }
    .topbar_leftIcon{
        font-size: 20px !important;
    }
    .topbar_rightIcon{
        font-size: 20px !important;
    }
    .topbar_left{
        margin-bottom: 5px;
    }
}


@media all and (max-width: 375px){
    .topbar_left p{
        font-size: 14px;
        text-align: center;
    }
    .topbar_right p{
        font-size: 14px;
        text-align: center;
    }
    .topbar_leftIcon{
        font-size: 20px !important;
    }
    .topbar_rightIcon{
        font-size: 20px !important;
    }
    .topbar_left{
        margin-bottom: 5px;
    }
}

@media all and (max-width: 320px){
    .topbar_left p{
        font-size: 14px;
        text-align: center;
    }
    .topbar_right p{
        font-size: 14px;
        text-align: center;
    }
    .topbar_leftIcon{
        font-size: 20px !important;
    }
    .topbar_rightIcon{
        font-size: 20px !important;
    }
    .topbar_left{
        margin-bottom: 5px;
    }
}