.about_info{
    position: relative;
    padding: 80px 0;
}
.about_infoTitle{
    position: relative;
    margin-bottom: 40px;
}
.about_infoTitleIcon{
    color: #ffcc29;
    font-size: 50px !important;
    font-weight: 600;
    margin-bottom: 8px;
}
.about_infoTitle h2{
    color: #373435;
    font-size: 35px;
    font-weight: 700;
    text-transform: capitalize;
}
.about_infoText span{
    color: #ffcc29;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
}
.about_infoText h4{
    color: #373435;
    font-size: 22px;
    font-weight: 600;
    text-transform: capitalize;
    margin: 10px 0;
}
.about_infoText p{
    color: #727376;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 10px;
}
.about_infoPic > img {
    object-fit: contain;
}
/* Responsive Media Queries */

@media all and (max-width: 991px){
    .about_infoText{
        margin-bottom: 30px;
    }
}

@media all and (max-width: 768px){
    .about_infoTitleIcon{
        font-size: 48px !important;
    }
    .about_infoTitle h2{
        font-size: 30px;
    }
    .about_infoText h4{
        font-size: 20px;
    }
}

@media all and (max-width: 425px){
    .about_info{
        padding: 50px 0;
    }
    .about_infoTitleIcon{
        font-size: 45px !important;
    }
    .about_infoTitle h2{
        font-size: 24px;
    }
    .about_infoText span{
        font-size: 14px;
    }
    .about_infoText h4{
        font-size: 18px;
    }
    .about_infoText p{
        font-size: 14px;
    }
}

@media all and (max-width: 375px){
    .about_info{
        padding: 50px 0;
    }
    .about_infoTitleIcon{
        font-size: 45px !important;
    }
    .about_infoTitle h2{
        font-size: 24px;
    }
    .about_infoText span{
        font-size: 14px;
    }
    .about_infoText h4{
        font-size: 18px;
    }
    .about_infoText p{
        font-size: 14px;
    }
}

@media all and (max-width: 320px){
    .about_info{
        padding: 50px 0;
    }
    .about_infoTitleIcon{
        font-size: 45px !important;
    }
    .about_infoTitle h2{
        font-size: 24px;
    }
    .about_infoText span{
        font-size: 14px;
    }
    .about_infoText h4{
        font-size: 18px;
    }
    .about_infoText p{
        font-size: 14px;
    }
}