.navbar{
    background-color: #ffffff;
    height: 100px;
    position: sticky;
    top: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.nav-menu{
    display: flex;
    align-items: center;
    justify-content: center;
}
.menu-icon{
    display: none;
}
.nav-item{
    padding: 0 30px;
}
.nav-links{
    color: black;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.3s ease;
}
.nav-links:hover{
    color: #ffcc29;
    text-decoration: none;
}

@media all and (max-width: 991px){
    .nav-menu{
        display: block;
        position: absolute;
        content: '';
        background-color: #ffffff;
        left: -100%;
        top: 100px;
        width: 100%;
        height: 100vh;
        opacity: 1;
        transition: all 0.5s ease;
    }
    .nav-menu.active{
        background-color: #ffffff;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }
    .nav-item{
        text-align: center;
        padding: 30px 0;
    }
    .menu-icon{
        display: block;
        font-size: 30px;
    }
}