.whatwe_do{
    position: relative;
    background-color: white;
    padding: 80px 0;
}
.whatwe_doImage > img {
    object-fit: contain;
}
.whatwe_doText span{
    color: #ffcc29;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
}
.whatwe_doText h2{
    color: #373435;
    font-size: 30px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 20px;
}
.whatwe_doText p{
    color: #767273;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    line-height: 30px;
    margin-bottom: 20px;
}
.whatwe_doText ul li{
    color: #767273;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 10px;
}
.whatwe_doText .check{
    color: #ffcc29;
    font-size: 20px !important;
    font-weight: bold;
    margin-right: 5px;
}

/* Responsive Media Queries */
@media all and (max-width: 991px){
    .whatwe_doText{
        margin-bottom: 30px;
    }
}

@media all and (max-width: 768px){
    .whatwe_doText h2{
        font-size: 24px;
    }
}


@media all and (max-width: 425px){
    .whatwe_do{
        padding: 50px 0;
    }
    .whatwe_doText span{
        font-size: 14px;
    }
    .whatwe_doText h2{
        font-size: 20px;
    }
    .whatwe_doText p{
        font-size: 14px;
        line-height: 28px;
    }
    .whatwe_doText .check{
        font-size: 18px !important;
    }
    .whatwe_doText ul li{
        font-size: 14px;
    }
}


@media all and (max-width: 375px){
    .whatwe_do{
        padding: 50px 0;
    }
    .whatwe_doText span{
        font-size: 14px;
    }
    .whatwe_doText h2{
        font-size: 20px;
    }
    .whatwe_doText p{
        font-size: 14px;
        line-height: 28px;
    }
    .whatwe_doText .check{
        font-size: 18px !important;
    }
    .whatwe_doText ul li{
        font-size: 14px;
    }
}

@media all and (max-width: 320px){
    .whatwe_do{
        padding: 50px 0;
    }
    .whatwe_doText span{
        font-size: 14px;
    }
    .whatwe_doText h2{
        font-size: 20px;
    }
    .whatwe_doText p{
        font-size: 14px;
        line-height: 28px;
    }
    .whatwe_doText .check{
        font-size: 18px !important;
    }
    .whatwe_doText ul li{
        font-size: 14px;
    }
}