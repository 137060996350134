.infrastructure_pic > img{
    object-fit: contain;
}

.infrastructure_text > h2{
    color: #373435;
    font-size: 35px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 20px;
}
.infrastructure_text > p{
    color: #767273;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 10px;
}
.infrastructure_text > ul{
    margin-bottom: 0;
}
.infrastructure_text > ul > li{
    color: #767273;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 5px;
    font-weight: 700;
}
.infrastructure_textIcon{
    color: #ffcc29;
    font-size: 50px !important;
    font-weight: 600;
    margin-bottom: 8px;
}
.infrastructure_listIcon{
    color: #ffcc29;
    font-size: 24px !important;
    font-weight: 400 !important;
}

/* Responsive Media Queries */
@media all and (max-width: 991px){
    .infrastructure_pic{
        margin-bottom: 30px;
    }
}

@media all and (max-width: 768px){
    .infrastructure_text > h2{
        font-size: 30px;
    }
    .infrastructure_textIcon{
        font-size: 48px !important;
    }
}

@media all and (max-width: 425px){
    .infrastructure{
        padding: 50px 0;
    }
    .infrastructure_text > h2{
        font-size: 24px;
    }
    .infrastructure_textIcon{
        font-size: 45px !important;
    }
    .infrastructure_text > p{
        font-size: 14px;
    }
    .infrastructure_listIcon{
        font-size: 20px !important;
    }
    .infrastructure_text > ul > li{
        font-size: 14px;
    }
}

@media all and (max-width: 375px){
    .infrastructure{
        padding: 50px 0;
    }
    .infrastructure_text > h2{
        font-size: 24px;
    }
    .infrastructure_textIcon{
        font-size: 45px !important;
    }
    .infrastructure_text > p{
        font-size: 14px;
    }
    .infrastructure_listIcon{
        font-size: 20px !important;
    }
    .infrastructure_text > ul > li{
        font-size: 14px;
    }
}

@media all and (max-width: 320px){
    .infrastructure{
        padding: 50px 0;
    }
    .infrastructure_text > h2{
        font-size: 24px;
    }
    .infrastructure_textIcon{
        font-size: 45px !important;
    }
    .infrastructure_text > p{
        font-size: 14px;
    }
    .infrastructure_listIcon{
        font-size: 20px !important;
    }
    .infrastructure_text > ul > li{
        font-size: 14px;
    }
}