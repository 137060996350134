.safety{
    padding: 80px 0;
}

/* Responsive Media Queries */
@media all and (max-width: 991px){
    .processImage{
        margin-bottom: 30px;
    }
}


@media all and (max-width: 425px){
    .safety{
        padding: 50px 0;
    }
}

@media all and (max-width: 375px){
    .safety{
        padding: 50px 0;
    }
}

@media all and (max-width: 320px){
    .safety{
        padding: 50px 0;
    }
}